import { useState } from "react";

import { LoaderFunctionArgs, type MetaFunction, redirect } from "@remix-run/node";
import { useFetcher, useLoaderData } from "@remix-run/react";
import { signInAnonymously } from "firebase/auth";
import fs from "fs/promises";
import path from "path";
import { useTranslation } from "react-i18next";

import { AppBar, BottomBar, Button, Container, Loader } from "~/components";
import { CheckboxLabel } from "~/components/CheckboxLabel";
import { APP_NAME } from "~/config";
import { auth as clientAuth } from "~/firebase.client";
import { VascoLogo } from "~/icons";
import { commitSession, getSession } from "~/sessions";
import utils, { Events } from "~/utils";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const uid = session.get("uid");
  const searchParams = new URL(request.url).searchParams;
  const code = searchParams.get("code");
  const origin = searchParams.get("origin");

  if (!uid) {
    const termsHtml = await fs.readFile(path.resolve("public", "html", "terms-en.html"), "utf-8");
    const privacyHtml = await fs.readFile(path.resolve("public", "html", "privacy-policy-en.html"), "utf-8");

    return { code, origin, termsHtml, privacyHtml };
  }

  if (code) {
    session.flash("code", code);
  }
  if (origin) {
    session.flash("origin", origin);
  }

  throw redirect("/profile", { headers: { "Set-Cookie": await commitSession(session) } });
};

export const meta: MetaFunction<typeof loader> = () => {
  return [{ title: APP_NAME }];
};

export default function Index() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const fetcher = useFetcher();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const { termsHtml, privacyHtml, code, origin } = useLoaderData<{
    termsHtml: string;
    privacyHtml: string;
    code: string;
    origin: string | null;
  }>();

  const onAcceptTerms = async () => {
    try {
      setIsLoading(true);
      const { user } = await signInAnonymously(clientAuth);
      const jwt = await user.getIdToken(true);
      fetcher.submit({ jwt, code }, { method: "post", action: "/login" });
      utils.analytics.logEvent(Events.RegulationsAccept, { origin: origin ?? "browser" });
    } catch (e: unknown) {
      setIsLoading(false);
      utils.error.capture("ACCEPT_TERMS", e);
    }
  };

  return (
    <Container>
      <AppBar>
        <VascoLogo />
      </AppBar>
      <div className="flex flex-1 flex-col">
        {isLoading && <Loader>{t("prompt_accepting_license")}</Loader>}
        <div dangerouslySetInnerHTML={{ __html: termsHtml }}></div>
        <div dangerouslySetInnerHTML={{ __html: privacyHtml }}></div>
        <CheckboxLabel
          label={t("label_noun_terms_and_conditions_checkbox")}
          id="terms"
          isChecked={isTermsAccepted}
          onChange={setIsTermsAccepted}
        />
        <CheckboxLabel
          label={t("label_noun_privacy_policy_checkbox")}
          id="privacyPolicy"
          isChecked={isPolicyAccepted}
          onChange={setIsPolicyAccepted}
        />
        <p className="mb-6 text-xs">{t("label_noun_required")}</p>
      </div>
      <BottomBar>
        <Button onClick={onAcceptTerms} disabled={!isTermsAccepted || !isPolicyAccepted}>
          {t("label_action_request_accept_and_continue")}
        </Button>
      </BottomBar>
    </Container>
  );
}
